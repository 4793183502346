import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 1.6rem;
  background-color: #f3f4f6;
`;

const Devider = () => {
  return <Container />;
};

export default Devider;
