import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 9.6rem;
  box-sizing: border-box;
  border-bottom: 0.1rem solid #e8e8e8;
  padding: 0 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
`;

const Image = styled.img`
  width: 2.4rem;
`;

const Delivery = () => {
  return (
    <Container>
      <Wrapper style={{ fontSize: "1.8rem", fontWeight: 700 }}>
        <Image
          style={{ aspectRatio: "24/32" }}
          src="https://www.flexdaycdn.net/public/images/ticket_ver2/bike_icon.png"
        />
        {"배달로 받을게요"}
      </Wrapper>
      <Wrapper style={{ fontSize: "1.6rem" }}>
        <Image
          style={{ aspectRatio: "1/1" }}
          src="https://www.flexdaycdn.net/public/images/ticket_ver2/clock_icon2.png"
        />
        {"17 ~ 32분 후 도착"}
      </Wrapper>
    </Container>
  );
};

export default Delivery;
