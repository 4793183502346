import styled from "styled-components";

const Container = styled.nav`
  width: 100%;
  height: 8.4rem;
  box-sizing: border-box;
  border-bottom: 0.1rem solid #e8e8e8;
  position: sticky;
  bottom: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.6rem;
  background-color: #2cc0ba;
  border-radius: 0.8rem;
  cursor: pointer;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 2.4rem;
  color: white;
`;

const BottomNav = () => {
  return (
    <Container>
      <Title>{"9,700원 결제하기"}</Title>
    </Container>
  );
};

export default BottomNav;
