import React from "react";
import Address from "./Address";
import Delivery from "./Delivery";
import Phone from "./Phone";

export default () => (
  <React.Fragment>
    <Delivery />
    <Address />
    <Phone />
  </React.Fragment>
);
