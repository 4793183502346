import React from "react";
import Gifticon from "./Gifticon";
import Result from "./Result";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  image,
  setImage,
  result = { couponType: "", result: [] },
  duration,
  onClickCheck,
}) => (
  <React.Fragment>
    <Gifticon image={image} setImage={setImage} />
    {result.result.length > 0 && (
      <Result result={result} duration={duration} onClickCheck={onClickCheck} />
    )}
    <div style={{ height: "8.4rem" }}></div>
  </React.Fragment>
);
