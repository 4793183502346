import React from "react";
import styled from "styled-components";
import {
  convertBarcode,
  convertExpire,
  convertMenu,
  convertName,
} from "../../../utils/common";

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-bottom: 0.1rem solid #e8e8e8;
  padding: 2.4rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  font-size: 2.4rem;
  font-weight: 700;
`;

const Wrapper = styled.div`
  font-size: 2rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;
const Text = styled.p`
  font-size: 1.6rem;
  color: blue;
`;
const Button = styled.button``;

const renderInferText = ({ name, inferText }) => {
  if (name === "menu") {
    return convertMenu(inferText);
  }
  if (name === "barcode") {
    return convertBarcode(inferText);
  }
  if (name === "expire") {
    return convertExpire(inferText);
  }

  return inferText;
};

const renderItems = (items = []) => {
  if (!items.length > 0) return;
  return (
    <React.Fragment>
      {items.map(({ name, inferText }, index) => {
        // eslint-disable-next-line array-callback-return
        if (!inferText) return;
        // eslint-disable-next-line array-callback-return
        if (name === "logo") return;
        return (
          <Wrapper key={index}>
            {convertName(name)}
            <Text>{renderInferText({ name, inferText })}</Text>
          </Wrapper>
        );
      })}
    </React.Fragment>
  );
};

const Result = ({
  result = { couponType: "", result: [] },
  duration,
  onClickCheck,
}) => {
  return (
    <Container>
      {`OCR 결과 ${result?.couponType && result.couponType + " 쿠폰"}`}
      {renderItems(result.result)}
      <Button
        onClick={() =>
          onClickCheck({
            barcode: convertBarcode(
              result.result.find((r) => r.name === "barcode").inferText
            ),
            expire: convertExpire(
              result.result.find((r) => r.name === "expire").inferText
            ),
          })
        }
      >
        위변조 검증
      </Button>
    </Container>
  );
};

export default Result;
