import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 25.6rem;
  box-sizing: border-box;
  border-bottom: 0.1rem solid #e8e8e8;
  padding: 2.4rem 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const Wrapper = styled.div``;

const Image = styled.img`
  height: 2.4rem;
`;

const Address = () => {
  return (
    <Container>
      <Wrapper
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.4rem",
          fontSize: "2.4rem",
          fontWeight: 700,
        }}
      >
        <Image
          style={{ aspectRatio: "24/28" }}
          src="https://www.flexdaycdn.net/public/images/ticket_ver2/map_icon.png"
        />
        {"회사"}
      </Wrapper>
      <Wrapper
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.4rem",
        }}
      >
        <p style={{ fontSize: "2.8rem" }}>{"남구 문현동 1227-2"}</p>
        <p style={{ fontSize: "2rem" }}>{"[도로명] 전포대로 133"}</p>
      </Wrapper>
      <Wrapper>
        <input
          type="text"
          style={{
            width: "100%",
            height: "6.4rem",
            padding: "1.6rem",
            boxSizing: "border-box",
            fontSize: "1.6rem",
            backgroundColor: "#f3f4f6",
            borderRadius: "0.8rem",
            outline: "none",
            border: "none",
          }}
          placeholder="상세주소를 입력해주세요 (건물명, 동/호수 등)"
        />
      </Wrapper>
    </Container>
  );
};

export default Address;
