import styled from "styled-components";
import Devider from "./Devider";
import Section1 from "./Section1";
import TopNav from "./TopNav";
import BottomNav from "./BottomNav";
import Section2 from "./Section2";
import { useEffect, useState } from "react";
import API from "../../utils/api";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const [image, setImage] = useState({
    file: null,
    url: null,
  });
  const [couponType, setCouponType] = useState(null);

  useEffect(() => {
    if (image?.file) {
      setResult({ couponType: "", result: [] });
      setCouponType(null);
      const formData = new FormData();
      formData.append("file", image.file);
      API.post("/ocr/template", formData)
        .then((res) => {
          setCouponType(res.data.couponType);
        })
        .catch((err) => alert(err?.response?.data?.message));
    }
  }, [image]);

  useEffect(() => {
    if (couponType) {
      const formData = new FormData();
      formData.append("file", image.file);
      formData.append("couponType", couponType);
      API.post("/ocr/classification", formData)
        .then((res) => {
          setResult(res.data);
          setDuration(res.duration);
        })
        .catch((err) => alert(err?.response?.data?.message));
    }
  }, [couponType]);

  const [result, setResult] = useState({ couponType: "", result: [] });
  const [duration, setDuration] = useState(null);

  const onClickCheck = ({ barcode, expire }) => {
    API.post(
      "/check",
      { barcode, expire },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res?.data?.use) {
          alert("사용 가능한 쿠폰입니다.");
        } else {
          alert("사용 불가능한 쿠폰입니다.");
        }
      })
      .catch((err) => alert(err?.response?.data?.message));
  };
  return (
    <Container>
      <TopNav />
      <Section1 />
      <Devider />
      <Section2
        image={image}
        setImage={setImage}
        result={result}
        duration={duration}
        onClickCheck={onClickCheck}
      />
      <BottomNav />
    </Container>
  );
};
