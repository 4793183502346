import styled from "styled-components";

const Container = styled.nav`
  width: 100%;
  height: 8.4rem;
  box-sizing: border-box;
  border-bottom: 0.1rem solid #e8e8e8;
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.6rem;
  background-color: white;
`;

const Image = styled.img`
  height: 2.4rem;
  cursor: pointer;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 2.4rem;
`;

const TopNav = () => {
  return (
    <Container>
      <Image
        style={{ aspectRatio: "1/2" }}
        src={"https://www.flexdaycdn.net/public/images/ticket/back-b.png"}
      />
      <Title>{"주문하기"}</Title>
      <Image
        style={{ aspectRatio: "1/1" }}
        src={
          "https://www.flexdaycdn.net/public/images/ticket_ver2/baemin_home.png"
        }
      />
    </Container>
  );
};

export default TopNav;
