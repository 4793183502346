import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 36rem;
  box-sizing: border-box;
  border-bottom: 0.1rem solid #e8e8e8;
  padding: 2.4rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  font-size: 2.4rem;
  font-weight: 600;
`;

const Image = styled.img`
  width: 100%;
  aspect-ratio: 465/285;
  cursor: pointer;
  object-fit: cover;
`;

const uploadLimit = 5242880;

const Gifticon = ({ image, setImage }) => {
  const onClickUpload = async ({ target: { files } }) => {
    if (files && files?.length > 0 && files instanceof FileList) {
      const file = files[0];
      if (file instanceof File) {
        if (!file.type.includes("image")) {
          return alert("이미지 외의 파일은 업로드할 수 없습니다.");
        }
        if (file.size > uploadLimit) {
          return alert("5MB를 초과하는 이미지는 업로드할 수 없습니다.");
        }
        let promiseErr = false;
        const fileUrl = await new Promise((resolve, reject) => {
          try {
            const reader = new FileReader();
            reader.onload = () => {
              resolve(reader.result);
            };
            reader.readAsDataURL(file);
          } catch (error) {
            reject(error.message);
          }
        }).catch((errMsg) => {
          promiseErr = true;
          return errMsg;
        });
        if (promiseErr) {
          alert(
            `이미지를 읽어오는 도중, 웹페이지에서 오류가 발생했습니다.\n오류 내용 : ${fileUrl}`
          );
          return;
        }
        setImage({ file, url: fileUrl });
      } else {
        // file이 진짜 File instance가 아닌 경우
        alert("알 수 없는 오류가 발생했습니다.\n올바른 파일인지 확인해주세요.");
        return;
      }
    } else {
      // input의 files value가 존재하지 않거나, 정상이 아닌 경우
      alert("알 수 없는 오류가 발생했습니다.\n새로고침 후 재시도 해주세요.");
      return;
    }
  };
  return (
    <Container>
      {"기프티콘"}
      <input
        id="gifticon"
        type="file"
        style={{ display: "none" }}
        onChange={(e) => onClickUpload(e)}
      />
      <label htmlFor="gifticon">
        <Image
          src={
            image?.file && image?.url
              ? image.url
              : "https://www.flexdaycdn.net/public/images/ticket_ver2/gift_1.png"
          }
        />
      </label>
    </Container>
  );
};

export default Gifticon;
