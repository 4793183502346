import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 16rem;
  box-sizing: border-box;
  border-bottom: 0.1rem solid #e8e8e8;
  padding: 2.4rem 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const Wrapper = styled.div``;
const Btn = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f4f6;
  border-radius: 2.4rem;
  font-size: 1.6rem;
  cursor: pointer;
`;
const CheckBox = styled.input`
  width: 2.4rem;
  height: 2.4rem;
  box-sizing: border-box;
  border-width: 0.2rem;
  border-color: #c3c3c3;
  border-radius: 0.2rem;
  &:checked {
    border-color: #2cc0ba;
  }
`;

const Phone = () => {
  return (
    <Container>
      <Wrapper
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "2.4rem",
          fontWeight: 600,
        }}
      >
        {"010-1234-5678"}
        <Btn width="5.6rem" height="3.2rem">
          {"변경"}
        </Btn>
      </Wrapper>
      <Wrapper
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "2rem",
          gap: "0.8rem",
        }}
      >
        <CheckBox type="checkbox" />
        {"안심번호 사용"}
        <Btn width="6.2rem" height="2.8rem">
          {"자세히"}
        </Btn>
      </Wrapper>
    </Container>
  );
};

export default Phone;
