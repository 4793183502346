export const convertName = (name = "") => {
  if (!name) return "-";
  switch (name) {
    case "menu":
      return "상품명";
    case "store":
      return "가맹점명";
    case "logo":
      return "로고명";
    case "barcode":
      return "쿠폰번호";
    case "usable":
      return "교환처";
    case "expire":
      return "유효기간";
    case "order":
      return "주문번호";
    default:
      return "-";
  }
};

export const convertMenu = (menu = "") => {
  if (!menu) return "-";
  // 줄바꿈 제거
  return menu.replace(/\n/g, "");
};

export const convertBarcode = (barcode = "") => {
  if (!barcode) return "-";
  // 줄바꿈 제거
  // 공백 제거
  // 하이픈 제거
  return barcode
    .replace(/\n/g, "")
    .replace(/ /g, "")
    .replace(/-/g, "")
    .replace(/—/g, "");
};

export const convertExpire = (expire = "") => {
  if (!expire) return "-";
  // 줄바꿈 제거
  // 공백 제거
  // 물결 제거
  // 하이픈 제거
  // 슬래시 제거
  // 점 제거
  // 년, 월, 일 제거
  return expire
    .replace(/\n/g, "")
    .replace(/ /g, "")
    .replace(/~/g, "")
    .replace(/-/g, "")
    .replace(/\//g, "")
    .replace(/\./g, "")
    .replace(/년/g, "")
    .replace(/월/g, "")
    .replace(/일/g, "");
};
