import { Outlet } from "react-router";
import styled from "styled-components";

const Layout = styled.div`
  width: 100vw;
  /* height: 100vh; */
  box-sizing: border-box;
  /* overflow: hidden; */
  display: flex;
  justify-content: center;
  background-color: #fafafa;
`;

const Container = styled.div`
  width: 100%;
  max-width: 48rem;
  min-width: 37.5rem;
  background-color: #fefefe;
`;

export default () => (
  <Layout>
    <Container>
      <Outlet />
    </Container>
  </Layout>
);
