import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10rem;
  font-size: 2.4rem;
`;

export default () => <Container>Error : 존재하지 않는 페이지입니다.</Container>;
