import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/Home";
import MainOutlet from "./MainOutlet";
import Error from "../pages/Error";

const root = "/";
export default () => {
  return createBrowserRouter([
    {
      path: root,
      element: <MainOutlet />,
      errorElement: <Error />,
      children: [
        {
          path: "",
          element: <Home />,
        },
      ],
    },
  ]);
};
